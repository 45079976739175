<template>
  <div>
    <div class="banner">
      <h2>ShuangzhaoDB 的特性使其非常适用于某些场景</h2>
      <h2>以下提供多个示例供您演示</h2>
    </div>
    <ul class="main content container">
      <li v-for="item in demoList" :key="item.id">
        <el-card @click.native="jumpToPage(item.url)" shadow="hover">
          <img :src="item.pic" />
          <div class="text">
            <div class="title">{{ item.title }}</div>
            <p class="desc" :title="item.content">
              {{ item.content }}
            </p>
            <div v-if="item.sub && item.sub.length">
              <div v-for="row in item.sub" :key="row.id">
                <div class="sub-title">{{ row.title }}</div>
                <p>{{ row.content }}</p>
              </div>
            </div>
          </div>
        </el-card>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Demo",
  data() {
    return {
      demoList: [
        {
          id: 1,
          title: "选举投票",
          url: "voter/index.html",
          pic: require(`@/assets/images/voter.jpg`),
          content:
            "模拟一场美国的电话选举。此应用程序专为基准测试而设计，将允许系统自动调整自身以获得最佳吞吐量，而不管您的底层硬件和集群部署如何 ， 让您对ShuangzhaoDB在硬件上的性能类型有一个很好的感受。",
        },

        {
          id: 2,
          title: "唯一设备标识",
          url: "uniquedevices/index.html",
          pic: require(`@/assets/images/uniquedevices.jpg`),
          content:
            "演示了对快速移动数据的实时分析。此示例应用程序模拟了每次有人启动移动应用程序时，都会向您的服务发送一条消息，其中包含应用程序标识符和代表设备的唯一ID。此应用可以帮助您估算在任何一天有多少特定设备使用了他们的应用程序。",
        },
        {
          id: 3,
          title: "最佳买卖报价",
          url: "nbbo/index.html",
          pic: require(`@/assets/images/nbbo.jpg`),
          content:
            "NBBO指的是全国最佳买入价和卖出价，定义为给定证券在参与市场中的最低的要价和最高的出价。此示例应用程序包含一个ShuangzhaoDB Schema，存储每个市场数据报价，并在最佳可买入价或卖出价发生变化时自动插入新的记录。这可为消费者提供当前的最佳买卖报价和历史交易记录。",
        },
        {
          id: 4,
          title: "地铁刷卡",
          url: "metrocard/index.html",
          pic: require(`@/assets/images/metrocard.jpg`),
          content:
            "此应用程序模拟了地铁卡执行高速交易处理，展示了高并发下每个车站的进出和刷卡事件。",
        },
        {
          id: 5,
          title: "呼叫中心",
          url: "callcenter/index.html",
          pic: require(`@/assets/images/callcenter.jpg`),
          content:
            "模拟处理呼叫中心呼叫事件全过程。在ShuangzhaoDB中匹配和插入事件来创建一个完整的通话记录 。 该应用利用了ShuangzhaoDB的强一致性和存储过程逻辑来计算代理调用长度的运行标准差。",
        },
        {
          id: 6,
          title: "银行报价",
          url: "bank-offers/index.html",
          pic: require(`@/assets/images/bank-offers.jpg`),
          content:
            "模拟了获取客户购买交易数据，并找到最佳匹配的报价，在销售网点呈现给客户的过程。该应用展示了ShuangzhaoDB在实时驱动网络交互的功能，例如提供描述报价的在线广告，从输入的帐户和供应商中检索。",
        },
        {
          id: 7,
          title: "广告性能",
          url: "adperformance/index.html",
          pic: require(`@/assets/images/adperformance.jpg`),
          content:
            "模拟网络投放广告的展示、点击和转化事件。这些事件在客户端中随机生成，并展示了接收到的事件流。此应用程序演示了ShuangzhaoDB中的几个SQL功能，包括：",
          sub: [
            { id: 11, title: "", content: "- 按表达式查看分组" },
            { id: 12, title: "", content: "- 数据过期的TTL处理" },
          ],
        },
      ],
    };
  },
  methods: {
    jumpToPage(url) {
      window.location.assign(url);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-color: #f2f2f2;
  h2 {
    padding: 10px 0;
    line-height: 40px;
    font-size: 40px;
    font-weight: 500;
    text-align: center;
  }
}
.content {
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  li {
    width: calc((100% - 50px) / 2);
    margin: 0 50px 50px 0;
    &:last-of-type {
      margin-bottom: 0;
    }
    &:nth-of-type(even) {
      margin-right: 0;
    }
    .el-card {
      cursor: pointer;
      &:hover .title {
        text-decoration: underline;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
      .text .title {
        font-size: 20px;
        font-weight: 600;
        color: @themeRed;
      }
    }
    @media screen and (min-width: 992px) {
      .el-card {
        height: 420px;
        img {
          height: 200px;
        }
      }
    }
  }
}

@media screen and(max-width:991px) {
  .banner {
    height: 200px;
    h2 {
      padding: 0 10px;
      font-size: 22px;
    }
  }
  .content {
    margin: 20px auto;
    li {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      /deep/ .el-card .el-card__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 40px;
        .title {
          text-decoration: underline;
        }
        img {
          max-height: 350px;
        }
      }
    }
  }
}
</style>